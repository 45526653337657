import https from "./https";
import store from "@/store";
import _ from "lodash";

const audience = {
  getAudiences(params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams(params);

    return https.get(
      `/admin/organizations/${organization.id}/audiences?${searchParams}`
    );
  },

  getAudience(audienceId) {
    return https.get(`/admin/audiences/${audienceId}`);
  },

  getCustomers(audienceId, params) {
    const searchParams = new URLSearchParams(params);
    return https.get(
      `/admin/audiences/${audienceId}/customers?${searchParams}`
    );
  },

  createAudience(audience) {
    const organization = store.state.general.organization;
    let formData = new FormData();
    _.map(audience, (value, key) => {
      formData.append(key, key != 'config' ? (value || '') : JSON.stringify(value));
    });
    return https.post(
      `/admin/organizations/${organization.id}/audiences`,
      formData
    );
  },

  updateAudience(audienceId, audience) {
    let formData = new FormData();
    _.map(audience, (value, key) => {
      formData.append(key, key != 'config' ? (value || '') : JSON.stringify(value));
    });
    formData.append("_method", "PUT");
    return https.post(`/admin/audiences/${audienceId}`, formData);
  },

  syncAudience(audienceId, audience) {
    return https.put(`/admin/audiences/${audienceId}/sync`, audience);
  },

  syncAudienceToLine(audienceId, audience) {
    return https.put(`/admin/audiences/${audienceId}/sync-to-line`, audience);
  },

  deleteAudience(audienceId) {
    return https.delete(`/admin/audiences/${audienceId}`);
  },
};

export default audience;
