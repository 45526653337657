<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CustomerList',
              }"
              >會員列表</b-breadcrumb-item
            >
            <b-breadcrumb-item
              :to="{
                name: 'AudienceList',
              }"
              >受眾列表</b-breadcrumb-item
            >
            <b-breadcrumb-item active>受眾會員</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">受眾會員</h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">           
            <b-table
              striped
              hover
              responsive
              :items="audiences"
              :fields="fields"
              :busy="isLoading"
              no-local-sorting
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              @change="getCustomers"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import PermissionChecker from "@/utils/PermissionChecker"
import * as consts from "@/consts";
import audienceApi from "@/apis/audience"
import moment from "moment"

export default {
  data() {
    return {
      consts: consts,
      isLoading: false,
      audiences: [],
      fields: [
        {
          key: "name",
          label: "LINE 名稱",
        },
        {
          key: "outer_code",
          label: "平台ID",
        },
        {
          key: "mobile_phone",
          label: "手機",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "birthday",
          label: "生日",
          formatter(birthday) {
            return moment(birthday).isValid() ? moment(birthday).format('YYYY-mm-DD') : ''
          }
        },
      ],
      currentPage: 1,
      perPage: 15,
      totalRows: 1,
      showLoading: false,
      showLoadingUpload: false,      
      keyword: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('general', ['branchOptions']),
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async init() {
      if (!this.checkPermission([consts.AUDIENCE_LIST_VIEW])) {
        this.$swal
          .fire({
            type: "error",
            text: "你沒有權限訪問此頁面",
            confirmButtonColor: "#d33",
          })
          .then(() => {
            this.$router.push({ name: "OrganizationList" });
          });
      } else {
        await this.getCustomers();
      }
    },
    async getCustomers(page = 1, orderBy = '', orderDesc = true) {
      try {
        this.isLoading = true;
        const { data } = await audienceApi.getCustomers(
          this.$route.params.audienceId,
          { page, order_by: orderBy, order_direction: orderDesc ? 'desc' : 'asc', per_page: this.perPage  }
        );
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
        this.audiences = data.data;
        console.log("loaded", this.audiences)
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

.advance-search {
  color: #337ab7;

  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}
</style>
